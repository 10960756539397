<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Criação - Elétrico Predial</h1>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações gerais</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do projeto
                  </label>
                  <input v-model="project_name" type="text" class="form-control" name="project_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Unidade Consumidora 
                  </label>
                  <input v-model="consumer_unit" type="text" class="form-control" name="project_name"
                    placeholder="Informe a UC" />
                </div>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-12" :class="{'col-md-6': is_cpf,  'col-md-5' : !is_cpf}">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome do titular da UC
                  </label>
                  <input v-model="uc_holder_name" type="text" class="form-control" name="uc_holder_name"
                    placeholder="Digite o Nome do projeto" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg"
                    placeholder="0123456" />
                </div>
              </div>
              <div v-if="!is_cpf"  class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    {{ is_cpf ? 'CPF' : 'CNPJ' }}
                  </label>
                  <input v-if="is_cpf" v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="000.000.000.-00" @input="autoUser" v-maska="'###.###.###-##'"/>
                  <input  v-if="!is_cpf" v-model="cpf_cnpj" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="01.001.001/0001-01" @input="autoUser" v-maska="'##.###.###/####-##'"/>
                </div>
              </div>
             
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-maska="'(##) #####-####'" v-model="phone" class="form-label"/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-maska="'#####-###'" v-model="cep" class="form-label" @change="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Numero
                  </label>
                  <input v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10"/>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc"/>
                </div>

              </div>
            </div>
            <div v-if="role == 2" class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Financeiro</h6>
                </span>
              </div>
              
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do projeto?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_project" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o valor do ATR/TRT?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_art_trt" v-bind="config"></money3>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual o valor pago?
                  </label>
                  <div class="input-group mb-1">
                    <money3 v-model="amount_paid" v-bind="config"></money3>
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-input mt-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Observação
                  </label>
                  <div class="areatext">
                    <quill-editor
                    v-model="observation"
                    :options="editorOptions">
                    </quill-editor>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="role == 2" class="row mt-5">
              <div class="col-12">
                <span class="form-label mb-3">
                  <h6 class="pl-10">Informação do Integrador</h6>
                </span>
              </div>
              
              <div class="col-md-6 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Qual será o Integrador?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="integrator">
                      <option value="0">Escolher...</option>
                      <option v-for="intergrator in intergrators" :key="intergrator.id"  :value="intergrator.id">{{intergrator.full_name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <span class="form-label ">
                  <h6 class="pl-10 mb-3">Anexos</h6>
                </span>
              </div>
              <!-- Select para adicionar anexos -->
              <div class="col-md-5 col-12 mb-3">
                <label for="anexoTipo" class="form-label">Tipos de anexos</label>
                <select v-model="selectedAnexo" @change="handleAnexoSelection" class="form-control">
                  <option value="0" disabled>Escolha o tipo de anexo</option>
                  <option v-for="option in availableAnexoOptions" :key="option.id" :value="option">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="col-12">
                <div class="row">

                  <!-- Exibe o campo de nome e arquivo diretamente para "Outros" -->
                  <div v-if="isOtherSelected" class="col-md-4 mb-3">
                    <label for="otherAnexoLabel" class="form-label">Nome do Anexo</label>
                    <div class="form-input">
                      <input v-model="customAnexoName" type="text" class="form-control"
                        placeholder="Digite o nome do anexo" />
                      <button @click="addCustomAnexo" class="btn btn-sm btn-success mt-2">Adicionar campo</button>
                    </div>

                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <!-- Loop para renderizar os campos de anexo (gerados após o nome) -->
                  <div v-for="(anexo, index) in anexos" :key="anexo.name" class="col-md-4 col-12 mb-3">
                    <div class="form-input">
                      <label :for="anexo.name" class="form-label">{{ anexo.label }}</label>

                    </div>
                    <input :id="anexo.name" ref="anexo.name" type="file"
                      @change="handleFileChange(anexo.name, $event)" />
                    <button @click="removeAnexo(index)" class="btn btn-sm btn-danger mt-2">Remover</button>
                  </div>
                </div>
              </div>


              <div v-if="!is_cpf" class="col-md-6 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Cartão CNPJ
                  </label>
                </div>
                <input id="anexo_7" ref="anexo_7" type="file" class="form-control" name="anexo_7" />
              </div>
              <div v-if="!is_cpf" class="col-md-6 col-12 mb-3">
                <div class="form-input">
                  <label for="formGroupExampleInput" class="form-label">
                    Contrato Social
                  </label>
                </div>
                <input id="anexo_8" ref="anexo_8" type="file" class="form-control" name="anexo_8" />
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="create">
                <span>Gerar Projeto</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";


export default {
  components:{
    NavBar,
    Sidebar
  },
  data() {
    return {
      intergrators: [],
      integrator: 0,
      role: this.$store.state.role,
      project_name: '',
      consumer_unit: '',
      uc_holder_name: '',
      cpf_cnpj: '',
      rg: '',
      phone: '',
      street: '',
      address_number: '',
      neighborhood: '',
      city: '',
      state: '',
      cep: '',
      company_name: '',
      observation: '',
      has_attachment: '',
      complement: '',
      type: 3,
      is_legal_entity: 0,

      is_cpf: true,
      token: Cookie.get('_token'),

       //Financeiro
      amount_project: 0,
      amount_art_trt: 0,
      amount_paid: 0,
      config: {
          masked: false,
          prefix: 'R$ ',
          suffix: '',
          thousands: '.',
          decimal: ',',
          precision: 2,
          disableNegative: false,
          disabled: false,
          min: null,
          max: null,
          allowBlank: false,
          minimumNumberOfCharacters: 0,
          shouldRound: true,
          focusOnRight: false,
      },

      selectedAnexo: 0, // Armazena o anexo selecionado do select
      customAnexoName: '', // Nome personalizado para "Outros"
      isOtherSelected: false, // Controla quando o campo de nome personalizado é mostrado
      anexos: [], // Lista de anexos dinâmicos
      anexosFiles: {}, // Armazena os arquivos selecionados
      nextAnexoId: 100, // Para criar nomes dinâmicos para anexos "Outros"
      anexoOptions: [
        { id: 1, name: 'Procuração' },
        { id: 2, name: 'Documento Pessoa Física' },
        { id: 3, name: 'Conta de Energia' },
        { id: 4, name: 'Foto do local' },
        { id: 5, name: 'Imagem com localização' },
        { id: 6, name: 'Relação de cargas' },
        { id: 7, name: 'Cartão CNPJ' },
        { id: 8, name: 'Contrato Social' },
        { id: 9, name: 'Projeto Arquitetônico' },
        { id: 10, name: 'Foto do padrão' },
        { id: 11, name: 'Foto do disjuntor do padrão' },
        { id: 12, name: 'Outros' }
      ],
      usedAnexos: [] // Lista para armazenar os anexos já usados (exceto "Outros")

    };
  },
  methods: {
    handleAnexoSelection() {
      if (this.selectedAnexo.name === 'Outros') {
        this.isOtherSelected = true; // Exibe o campo de nome para "Outros"
      } else {
        this.addAnexo(); // Adiciona diretamente os anexos que não são "Outros"
      }
    },
    addCustomAnexo() {
      // Adiciona o bloco para o anexo do tipo "Outros" com o nome e gera o campo de arquivo
      if (this.customAnexoName) {
        const newAnexoName = `anexo_${this.nextAnexoId++}`;

        // Adiciona o bloco de anexo com o nome
        this.anexos.push({
          id: this.nextAnexoId,
          name: newAnexoName,
          label: this.customAnexoName
        });

        // Limpa o campo de nome e oculta o campo "Outros"
        this.customAnexoName = '';
        this.isOtherSelected = false; // Reseta a seleção para permitir adicionar outro "Outros"
        this.selectedAnexo = 0; // Reseta o select para permitir novas seleções
      }
    },
    handleFileChange(anexoName, event) {
      this.anexosFiles[anexoName] = event.target.files[0]; // Armazena o arquivo no estado
    },
    addAnexo() {
      const anexoLabel = this.selectedAnexo.name;
      const anexoId = this.selectedAnexo.id;

      if (anexoLabel) {
        const newAnexoName = `anexo_${anexoId}`;

        this.anexos.push({
          id: anexoId,
          name: newAnexoName,
          label: anexoLabel
        });

        this.usedAnexos.push(this.selectedAnexo.id); // Marca como usado
        this.selectedAnexo = 0; // Reseta o select após adicionar o anexo
      }
    },
    removeAnexo(index) {
      const anexo = this.anexos[index];
      if (anexo.id !== this.nextAnexoId - 1) { // Se não for um anexo do tipo "Outros"
        this.usedAnexos = this.usedAnexos.filter(id => id !== anexo.id); // Remove dos usados
      }
      this.anexos.splice(index, 1); // Remove o anexo da lista
      delete this.anexosFiles[anexo.name]; // Remove o arquivo associado
    },
    clear() {
      this.project_name= '';
      this.consumer_unit= '';
      this.uc_holder_name= '';
      this.cpf_cnpj= '';
      this.rg= '';
      this.phone= '';
      this.street= '';
      this.address_number= '';
      this.neighborhood= '';
      this.city= '';
      this.state= '';
      this.cep= '';
      this.company_name= '';
      this.observation= '';
      this.has_attachment= '';
      this.complement= '';
      this.type = 4;
      this.is_legal_entity = 0;

      this.is_cpf= true;

      this.anexo_1 = '';
      this.anexo_2 = '';
      this.anexo_3 = '';
      this.anexo_5 = '';
      this.anexo_7 = '';
      this.anexo_8 = '';
      this.anexo_9 = '';
      this.anexo_10 = '';
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
      this.cpf_cnpj = '';
    },
    create() {

      if (this.role == 2 && this.integrator == 0) {
        return this.showAlert('error', 'Escolha um integrador para o projeto.');
      }

      const formData = new FormData();

      var json = {
        project_name: this.project_name,
        consumer_unit: this.consumer_unit,
        uc_holder_name: this.uc_holder_name,
        cpf_cnpj: this.cpf_cnpj,
        rg: this.rg,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        zip_code: this.cep,
        company_name: this.company_name,
        observation: this.observation,
        has_attachment: this.has_attachment,
        complement: this.complement,
        project_type: this.type,
        is_legal_entity: this.is_legal_entity,
        integrator: (this.role == 2)? this.integrator : 0,

        //Financeiro
        amount_project: this.amount_project,
        amount_art_trt: this.amount_art_trt,
        amount_paid: this.amount_paid,

        anexos: {},

      };

      this.anexos.forEach(anexo => {
        if (this.anexosFiles[anexo.name]) {

          formData.append(anexo.name, this.anexosFiles[anexo.name]);
          formData.append(`${anexo.name}_name`, anexo.label);
        }
      });
      
      Object.keys(json).forEach(key => {
        formData.append(key, json[key]);
      });

      this.$http
        .post("user/projects", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          //this.clear();
          this.showAlert('success', response.data.message);
          this.$router.push('/projetos/eletrico-predial');
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    search(){
      if(!this.cep || this.cep.length < 9){
        return;
      }

      this.$http.get('/cep/'+this.cep)
      .then( (response) =>{
        this.street= response.data.logradouro;
        this.neighborhood= response.data.bairro;
        this.city= response.data.localidade;
        this.state= response.data.uf;

        const inputs = this.$el.querySelectorAll('input[readonly]');

        inputs.forEach(input => {
          input.removeAttribute('readonly');
        });
        
      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
    loadIntergrator() {
      this.$http.get("user/integrators", {
          headers: {
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.intergrators = response.data.items;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    autoUser(){
      if(!this.cpf_cnpj || (this.is_legal_entity == 0 && this.cpf_cnpj.length < 11) || (this.is_legal_entity == 1 && this.cpf_cnpj.length < 14)){
        return;
      }

      var json = {
        'number': this.cpf_cnpj,
        'type': this.is_legal_entity
      }

      this.$http.post('/user/search', json,{
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": "Bearer " + this.token
          },
        })
      .then( (response) =>{

        if(!response.data.status){
          return;
        }


        this.uc_holder_name= response.data.items.full_name;
        this.rg= response.data.items.rg;
        this.phone= response.data.items.phone;
        this.state= response.data.items.state;

        this.integrator= response.data.items.integrators;
        this.is_legal_entity= response.data.items.is_legal_entity;

        if(this.is_legal_entity){
          this.company_name= response.data.extra.company_name;
        }

        this.address_number= response.data.items.address_number;
        this.street= response.data.items.street;
        this.neighborhood= response.data.items.neighborhood;
        this.city= response.data.items.city;
        this.state= response.data.items.state;
        this.cep= response.data.items.zip_code;
        this.complement= response.data.items.complement;

        const inputs = this.$el.querySelectorAll('input[readonly]');

        inputs.forEach(input => {
          input.removeAttribute('readonly');
        });
        
      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
  },
  created(){
    this.loadIntergrator();
  }
};
</script>
