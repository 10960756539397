<template>
  <div>
    <Sidebar />
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Leads</h1>
          </div>
        </div>

        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-list-ul'></i>
              <h3>Em Analise</h3>
              <button @click="refresh" class="btn btn-primary btn-all">
                <i class='bx bx-refresh'></i> Atualizar
              </button>
              <button @click="doCopy" class="btn btn-primary btn-all">
                Calculadora
              </button>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Nomes</th>
                  <th>Valor</th>
                  <th>Criação</th>
                  <th>Ação</th>
                </tr>
              </thead>

              <tbody v-if="!is_refresh">
                <tr v-for="item in list_pending" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ amountConvert(item.spent) }}</td>
                  <td>{{ formatDate(1, item.created_at) }}</td>
                  <td class="actions-icon">
                    <i v-b-modal.modal-view class='bx bxs-detail ml-5 mr-5 icon-primary' @click="view(item.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="is_refresh" class="d-flex gap-2 text-center mt-4 justify-content-center">
              <i class='bx bx-refresh icon-primary'></i> Carregando ...
            </div>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class='bx bx-list-ul'></i>
              <h3>Lista de Verificados</h3>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Nomes</th>
                  <th>Valor</th>
                  <th>Situação</th>
                  <th>Criação</th>
                  <th>Atualização</th>
                  <th>Ação</th>
                </tr>
              </thead>

              <tbody v-if="!is_refresh">
                <tr v-for="item in list_complete" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ amountConvert(item.spent) }}</td>
                  <td>
                    <span :class="getStatusClass(item.is_confirm)">
                      {{ getStatusText(item.is_confirm) }}
                    </span>
                  </td>
                  <td>{{ formatDate(1, item.created_at) }}</td>
                  <td>{{ formatDate(1, item.updated_at) }}</td>
                  <td class="actions-icon">
                    <i v-b-modal.modal-view class='bx bxs-detail ml-5 mr-5 icon-primary' @click="view(item.id)"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="is_refresh" class="d-flex gap-2 text-center mt-4 justify-content-center">
              <i class='bx bx-refresh icon-primary'></i> Carregando ...
            </div>
          </div>
        </div>
        
        <b-modal id="modal-view" ref="modal-view"  title="Informações" class="box" hide-footer>
          <div v-if="is_refresh_lead" class="d-flex gap-2 text-center mt-4 mb-4 justify-content-center">
            <i class='bx bx-refresh icon-primary'></i> Carregando ...
          </div>
          <div v-if="!is_refresh_lead" class="row">
            <div class="col-12">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Nome</label>
                <input v-model="name" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Telefone</label>
                <input v-model="phone" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Valor da Conta</label>
                <input v-model="spent" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-8 col-12 pr-0">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Logradouro</label>
                <input v-model="address" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-4 col-6">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">CEP</label>
                <input v-model="cep" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-4 col-6">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Cidade</label>
                <input v-model="city" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-md-3 col-6">
              <div class="form-input mb-2">
                <label for="formGroupExampleInput" class="form-label">Estado</label>
                <input v-model="uf" type="text" class="form-control" readonly>
              </div>
            </div>
            <div class="col-12">
              <div class="w-100 d-flex align-items-center justify-content-between mt-5">
                <div class="d-flex align-items-center gap-1">
                  <button v-if="!status" @click="action(1)" class="btn btn-sm btn-success">Confirmar</button>
                  <button v-if="status" @click="action(2)" class="btn btn-sm btn-danger">Cancelrar</button>
                  <button @click="action(3)" class="btn btn-sm btn-secondary">Deletar</button>
                </div>

                <a target="_blank" :href="whatsapp + phone" class="btn btn-sm btn-danger btn-all">Entrar em contato</a>
              </div>
            </div>
          </div>
        </b-modal>
      </main>
    </div>

  </div>
</template>
<script>

import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components: {
    NavBar,
    Sidebar
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      spent: '',
      cep: '',
      uf: '',
      city: '',
      address: '',
      status: '',
      idLead: 0,
      whatsapp: 'https://api.whatsapp.com/send?phone=',
      list_pending: [],
      list_complete: [],
      author: "",
      token: Cookie.get('_token'),
      uuid: Cookie.get('link'),
      is_refresh: true,
      is_refresh_lead: true,
    };
  },
  methods: {

    load() {

      if (!this.is_refresh) {
        this.is_refresh = true;
      }

      this.$http.get("user/leads", {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {
          this.list_complete = response.data.list_complete;
          this.list_pending = response.data.list_pending;

          this.uuid = response.data.link.uuid;

          this.is_refresh = false;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        text: textMsg,
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    doCopy() {
      this.$copyText(this.$base + 'calculator/' + this.uuid).then(() => {
        this.showAlert('success', 'Seu link de calculadora foi copiado!')
      });
    },
    formatDate(type, date) {
      if (!date) {
        return '';
      }

      const dateFormat = new Date(date);

      if (type == 0) {
        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()}`;
      } else {

        return `${dateFormat.getDate().toString().padStart(2, '0')}/${(dateFormat.getMonth() + 1).toString().padStart(2, '0')}/${dateFormat.getFullYear()} às ${dateFormat.getHours().toString().padStart(2, '0')}:${dateFormat.getMinutes().toString().padStart(2, '0')}`;
      }

    },
    getStatusClass(status) {
      switch (status) {
        case 1: // completed
          return 'status completed';
        case 0: // cancelled
          return 'status cancelled';
        default:
          return 'status pending';
      }
    },
    getStatusText(status) {
      switch (status) {
        case 1: // completed
          return 'Confirmado';
        case 0: // pending
          return 'Negado';
        default:
          return 'Desconhecido';
      }
    },
    action(type) {

      var json = {
        type:type,
      }

      this.$http.put("/user/leads/"+this.idLead, json, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }).then((response) => {
          if(response.data.status == true){
            this.$bvModal.hide('modal-view')
            this.load();
          }

          this.showAlert('success', response.data.message);
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }
        });
    },
    refresh() {
      this.load();
    },
    view(id) {
      this.is_refresh_lead = true;

      this.$http.get("user/leads/" + id, {
        headers: {
          "Authorization": "Bearer " + this.token
        },
      })
        .then((response) => {
          this.idLead = id;

          this.name = response.data.item.name;
          this.email = response.data.item.email;
          this.phone = response.data.item.phone;
          this.spent = 'R$' + response.data.item.spent;
          this.cep = response.data.item.cep;
          this.address = response.data.item.address;
          this.city = response.data.item.city;
          this.uf = response.data.item.state;
          this.status = response.data.item.is_complete;

          this.is_refresh_lead = false;
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
  },
  created() {
    this.load();
  },
};
</script>