<template>
  <div>
    <Sidebar/>
    <div class="content">
      <NavBar />
      <main>
        <div class="header">
          <div class="left">
            <h1>Integrador - {{ full_name }}</h1>
          </div>
        </div>
        <div class="bottom-data">
          <div class="orders">
            <div class="header">
              <i class="bx bx-receipt"></i>
              <h3>Informações</h3>
            </div>
            <div class="row">
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Integrador
                  </label>
                  <input v-model="full_name" type="text" class="form-control" name="full_name"
                    placeholder="Nome do integrador" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CPF
                  </label>
                  <input v-model="cpf" type="text" class="form-control" name="cpf_cnpj"
                    placeholder="000.000.000.-00" v-maska="'###.###.###-##'"/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    RG
                  </label>
                  <input v-model="rg" type="text" class="form-control" name="rg"
                    placeholder="0123456" />
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Será Empresa?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_legal_entity" @change="legalEntity">
                      <option value="0">Não</option>
                      <option value="1">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail
                  </label>
                  <input v-model="email" type="email" class="form-control" name="email"
                    placeholder="teste@teste.com" />
                </div>
              </div>
              <div v-if="!is_cpf"  class="col-md-5 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Nome da Empresa
                  </label>
                  <input v-model="company_name" type="text" class="form-control" name="company_name"
                    placeholder="Razão Social" />
                </div>
              </div>
              <div v-if="!is_cpf" class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CNPJ
                  </label>
                  <input v-model="cnpj" type="text" class="form-control" name="cnpj"
                    placeholder="01.001.001/0001-01" />
                </div>
              </div>
             
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Telefone
                  </label>
                  <input v-maska="'(##) #####-####'" v-model="phone" class="form-label"/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    CEP
                  </label>
                  <input v-maska="'#####-###'" v-model="cep" class="form-label" @change="search" />
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Cidade
                  </label>
                  <input v-model="city" type="text" class="form-control" name="city"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Estado
                  </label>
                  <input v-model="state" type="text" class="form-control" name="state"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-2 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Numero
                  </label>
                  <input v-model="address_number" type="text" class="form-control" name="address_number"
                    placeholder="Ex: 10"/>
                </div>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Logradouro
                  </label>
                  <input v-model="street" type="text" class="form-control" name="street"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              <div class="col-md-3 col-6">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Bairro
                  </label>
                  <input v-model="neighborhood" type="text" class="form-control" name="neighborhood"
                    placeholder="Aguardando..." readonly/>
                </div>
              </div>
              
              <div class="col-md-3 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label" readonly>
                    Complemento
                  </label>
                  <input v-model="complement" type="text" class="form-control" name="complement"
                    placeholder="Ex: Casa, APT, Sítio, etc"/>
                </div>
                
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    Status
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="is_active">
                      <option value="0">Inativo</option>
                      <option value="1">Ativo</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-12">
                <div class="form-input mb-3">
                  <label for="formGroupExampleInput" class="form-label">
                    E-mail Verificado?
                  </label>
                  <div class="input-group mb-1">
                    <select class="form-control" v-model="id_account_status">
                      <option value="1">Não</option>
                      <option value="2">Sim</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-end mt-5">
              <button class="btn btn-primary btn-all" @click="update">
                <span>Alterar</span>
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>

  </div>
</template>

<script>
import Cookie from 'js-cookie'
import NavBar from "@/components/NavBar.vue";
import Sidebar from "@/components/SideBar.vue";

export default {
  components:{
    NavBar,
    Sidebar,
  },
  data() {
    return {
      full_name: '',
      cpf: '',
      rg: '',
      is_legal_entity: 0,
      email: '',
      phone: '',
      cep: '',
      address_number: '',
      street: '',
      neighborhood: '',
      city: '',
      state: '',
      company_name: '',
      complement: '',
      cnpj: '',
      is_cpf: true,
      is_active: 0,
      id_account_status: 1,
      token: Cookie.get('_token'),
    };
  },
  methods: {
    showAlert(type, msg) {
      var textMsg = "";

      if (msg.message) {


        if (typeof msg.errors === 'object' && msg.errors) {
          for (const key in msg.errors) {
            if (Object.prototype.hasOwnProperty.call(msg.errors, key)) {
              const errorMessages = msg.errors[key];
              textMsg += `${errorMessages.join(', ')}<br>`;
            }
          }
        } else {
          textMsg = `${msg.message}`;
        }
      } else {
        textMsg = `${msg}`;
      }

      this.$swal({
        icon: type,
        confirmButtonText: 'Confirmar',
        html: textMsg
      });
    },
    amountConvert(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    legalEntity() {
      this.is_cpf = !this.is_cpf;
    },
    update() {

      var json = {
        full_name: this.full_name,
        cpf: this.cpf,
        rg: this.rg,
        email: this.email,
        phone: this.phone,
        street: this.street,
        address_number: this.address_number,
        neighborhood: this.neighborhood,
        city: this.city,
        state: this.state,
        cep: this.cep,
        company_name: this.company_name,
        complement: this.complement,
        is_legal_entity: this.is_legal_entity,
        is_active: this.is_active,
        id_account_status: this.id_account_status,
        cnpj: this.cnpj
      };

      this.$http
        .put("user/integrators/"+this.$route.params.user, json, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
          this.showAlert('success', response.data.message);
          this.load();
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    },
    search(){
      if(!this.cep || this.cep.length < 9){
        
        return;
      }

      this.$http.get('/cep/'+this.cep)
      .then( (response) =>{
        console.log(response);
        this.street= response.data.logradouro;
        this.neighborhood= response.data.bairro;
        this.city= response.data.localidade;
        this.state= response.data.uf;
      }).catch((error) => {
        if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
        } else {
          this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
        }
      })
    },
    load(){
      this.$http
        .get("user/integrators/"+this.$route.params.user, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.token
          },
        })
        .then((response) => {
            if(response.data.items.is_legal_entity == 1){
              
              if(this.is_cpf){
                this.is_cpf = !this.is_cpf;
              }
              
              this.company_name = response.data.items.company_name;
            }

            this.full_name = response.data.items.full_name;
            this.cpf = response.data.items.cpf;
            this.rg = response.data.items.rg;
            this.email = response.data.items.email;
            this.phone = response.data.items.phone;
            this.street = response.data.items.street;
            this.address_number = response.data.items.address_number;
            this.neighborhood = response.data.items.neighborhood;
            this.city = response.data.items.city;
            this.state = response.data.items.state;
            this.cep = response.data.items.zip_code;
            this.complement = response.data.items.complement;
            this.is_legal_entity = response.data.items.is_legal_entity;
            this.is_active = response.data.items.is_active;
            this.id_account_status = response.data.items.id_account_status;

            if(response.data.items.is_legal_entity == 1){
              this.cnpj = response.data.extra.cnpj;
              this.company_name = response.data.extra.company_name;
            }
        })
        .catch((error) => {
          if (error.response && error.response.data) {
            this.showAlert('error', error.response.data);
          } else {
            this.showAlert('error', 'Ocorreu um erro desconhecido. Entre em contato com a equipe de desenvolvimento.');
          }

        });
    }
  },
  created(){
    this.load();
  }
};
</script>
